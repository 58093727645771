import {graphql} from "gatsby";
import React from "react";

import Layout from "../../components/Layout";
import WhyMason, { WhyMasonPageType } from "../../components/pages/modemagic/WhyMason";
import "../styles.scss";

const MerchandisePage = ({data: cmsData}) => {
  const {
    hero,
    infoCardsColoredSection,
    infoCardsWhiteSection,
    aboveFooterSection,
  } = cmsData.allSanityMerchandisePage.nodes[0] ?? {};

  const {
    hero: {socialProofHomepage},
  } = cmsData.allSanityModemagicPage.nodes[0] ?? {};

  const seoData = cmsData.allSanitySeoMeta.nodes[0];

  return (
    <Layout type='modemagic' mmv3 seoData={seoData}>
      <div className='full-bleed'>
        <WhyMason
          heroData={hero}
          coloredCardSectionData={infoCardsColoredSection}
          plainCardSectionData={infoCardsWhiteSection}
          aboveFooterSectionData={aboveFooterSection}
          socialProofBannerData={socialProofHomepage}
          pageType={WhyMasonPageType.MERCHANDISE}
          v3
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allSanitySeoMeta(filter: {page: {eq: "merchandise"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    allSanityMerchandisePage {
      nodes {
        hero {
          _rawHeading
          _rawDescription
          _rawMobileDescription
          _rawAuditCtaText
          auditCTA {
            url
          }
          mobileAuditCTA {
            text
            url
          }
          cta {
            text
            responseText
            action
            url
            mobileRedirectUrl
          }
          belowCta
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alt
          mobileImage {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          mobileAlt
          video {
            type
            showThumbnail
            ytId
            file {
              asset {
                url
              }
            }
            image {
              image {
                asset {
                  url
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              alt
            }
          }
          show
        }
        infoCardsColoredSection {
          sectionTitle
          sectionDescription
          infoCardsContent {
            cardTitle
            _rawCardDescription
            cardImage {
              alt
              image {
                asset {
                  gatsbyImageData
                }
              }
            }
            cardCTA {
              text
              url
            }
          }
        }
        infoCardsWhiteSection {
          sectionTitle
          sectionDescription
          infoCardsContent {
            cardTitle
            _rawCardDescription
            cardImage {
              alt
              image {
                asset {
                  gatsbyImageData
                }
              }
            }
            cardCTA {
              text
              url
            }
          }
        }
        aboveFooterSection {
          sectionTitle
          infoCardsContent {
            cardTitle
            _rawCardDescription
            cardImage {
              alt
              image {
                asset {
                  gatsbyImageData
                }
              }
            }
            cardCTA {
              url
              text
            }
          }
        }
      }
    }
    allSanityModemagicPage(filter: {page: {eq: "mmv3"}}) {
      nodes {
        hero {
          socialProofHomepage {
            alt
            image {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;

export default MerchandisePage;
